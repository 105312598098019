import React from 'react';

const Exklusives = () => {
    return (
        <div className="section exklusives">
           <h2 className="title has-text-centered">So funktioniert unser exklusives Partnerprogramm</h2>
               <div className="columns is-multiline">
             <div className="column is-three-fifths">
                    <div className="container-l bottom plantext has-text-centered">
                    <p className="has-text-centered">
                    Durch die Vermittlung durch unseren Premium-Partner profitieren Sie vom einmaligen und exklusiven <span class="numtextblue">MeinAlarm24-Rabatt in Höhe von 15%!</span>

                    <br/><br/><h3 className="has-text-centered middletext">
    MeinAlarm24 ist stets auf der Suche nach zuverlässigen und professionellen Partnern und hat sich ein eigenes Partnernetzwerk aufgebaut, über welches exklusive Angebotskonditionen ermöglicht werden. 
</h3><br/>
<p><h3 className="has-text-centered middletext">
Dabei werden nur Partner ausgewählt, die höchsten Qualitätsanspruch haben und bei denen die Kundenzufriedenheit im Mittelpunkt steht.</h3></p><br/>

<h3 className="has-text-centered middletext">
Einer unserer Partner hat uns empfohlen.<br/><br/>

                        <span className="offernumtext numtextblue">
                            
Sichern Sie sich daher direkt Ihren exklusiven MeinAlarm24-Rabatt<br/> in Höhe von 15%!

</span> </h3></p> 

                    </div>
                </div>
                <div className="column is-two-fifths">
                    <div className="container-r bottom has-text-centered">
                    </div>
                </div>
            </div>
           
        </div>   
    );
};

export default Exklusives;
