import React from "react";
import StringBuilder from "../helper/StringBuilder";
import Fade from "react-reveal/Fade";

export default class LeadHowTo2 extends React.Component {
    render() {
        const slug =
            typeof this.props.city !== "undefined"
                ? this.props.city.slug
                : this.props.city;
        let stringBuilder = new StringBuilder(
            this.props.city,
            this.props.product
        );
        let icon3Route = "/img/image_3_wir_installieren_fuer_sie_Einbruch.svg";
        if (this.props.product) {
            icon3Route =
                "/img/image_3_wir_installieren_fuer_sie_" +
                this.props.product +
                ".svg";
        }
        return (
            <div className="section how-to2">
                <div className="body">
                    <div className="columns">
                        <div className="column has-text-centered">
                            <h2 className="title">
                                In 3 einfachen Schritten zu{" "}
                                {stringBuilder.getHowToTitle() +
                                    stringBuilder.getInStadtUndUmgebung()}
                            </h2>
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column has-text-centered">
                            <div className="card">
                                <div className="card-image">
                                    <Fade top>
                                        <figure className="image">
                                            <img
                                                src="/img/image_1_bedarf_ermitteln.svg"
                                                alt="Bedarf ermitteln"
                                            />
                                        </figure>
                                    </Fade>
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <div className="title">
                                            1. Preis berechnen
                                        </div>
                                        <div className="text">
                                            Geben Sie die Informationen zu der
                                            Immobilie an, die Sie sichern
                                            möchten. Sie erhalten eine
                                            detaillierte Preiskalkulation für
                                            eine{" "}
                                            {stringBuilder.getSingularProductName() +
                                                stringBuilder.getInStadtUndUmgebung()}
                                            .
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column has-text-centered">
                            <div className="card">
                                <div className="card-image">
                                    <Fade top>
                                        <figure className="image">
                                            <img
                                                src="/img/image_2_kostenlose_beratung.svg"
                                                alt="Kostenlose Beratung"
                                            />
                                        </figure>
                                    </Fade>
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <div className="title">
                                            2. Sicherheitskonzept abstimmen
                                        </div>
                                        <div className="text">
                                            Ob telefonisch oder bei Ihnen vor
                                            Ort - unsere Sicherheitsberater
                                            {stringBuilder.getAusStadtUndUmgebung()}{" "}
                                            stehen Ihnen bei allen Fragen rund
                                            um Ihre Sicherheit mit Rat und Tat
                                            zur Verfügung.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column has-text-centered">
                            <div className="card">
                                <div className="card-image">
                                    <Fade top>
                                        <figure className="image">
                                            <img
                                                src={icon3Route}
                                                alt="Wir installieren für sie"
                                            />
                                        </figure>
                                    </Fade>
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <div className="title">
                                            3. Wir installieren die Anlage
                                        </div>
                                        <div className="text">
                                            Lehnen Sie sich zurück und fühlen
                                            Sie sich sicher: Unser Team
                                            übernimmt auch die Installation
                                            Ihrer neuen{" "}
                                            {stringBuilder.getSingularProductName()}{" "}
                                            mit eigenen Sicherheitstechnikern.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        );
    }
}
