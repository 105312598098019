import React from "react";
import { useRef } from "react";
import LeadProducts from "../components/LeadProducts";
import LeadHowTo2 from "../components/LeadHowTo2";
import LeadTestimonials from "../components/LeadTestimonials";
import Exklusives from "../components/Exklusives";
import KooperationComp1 from "../components/KooperationComp1";
import HowTo2 from "../components/HowTo2";

const LeadPartnerExklusives = () => {
    const rabatt = useRef();

    const handleClick = () => {
        rabatt.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="section exklusives">
            <div className="hero is-light default-hero">
                <div className="hero-image">
                    <picture>
                        <source
                            media="(max-width:768px)"
                            srcSet="/img/hero/default/alarmanlagen-von-der-nr-1-fuer-sicherheit-mobile2.jpg"
                        />
                        <source
                            media="(max-width:1024px)"
                            srcSet="/img/hero/default/alarmanlagen-von-der-nr-1-fuer-sicherheit-3.jpg"
                        />
                        <source
                            media="(max-width:1216px)"
                            srcSet="/img/hero/default/alarmanlagen-von-der-nr-1-fuer-sicherheit-3.jpg"
                        />
                        <source
                            media="(min-width:1217px)"
                            srcSet="/img/hero/default/alarmanlagen-von-der-nr-1-fuer-sicherheit-3.jpg"
                            vb
                        />
                        <img
                            src="/img/hero/default/alarmanlagen-von-der-nr-1-fuer-sicherheit-3.jpg"
                            style={{ width: "auto" }}
                            alt="MeinAlarm24: Deutschlands Nr.1 für Sicherheitstechnik"
                            title="MeinAlarm24: Deutschlands Nr.1 für Sicherheitstechnik"
                        />
                    </picture>
                </div>
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div className="hero-box">
                            <div className="product homepage">
                                <h1 className="top">
                                    Das MeinAlarm24 Partnerprogramm
                                </h1>
                                <span class="middle">
                                    Vertrauensvoll. Kompetent. Sicher.
                                </span>
                                <br />
                            </div>
                            <a
                                className="button preisrechner-btn preisrechner-btn-primary custom-primary1"
                                onClick={handleClick}
                            >
                                Für Geworbene: Jetzt Rabatt sichern!
                            </a>
                            <a
                                className="button preisrechner-btn preisrechner-btn-primary custom-primary2"
                                onClick={handleClick}
                            >
                                Für Partner: Interessenten übermitteln
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <KooperationComp1 />
            <HowTo2 showVideo="false" />
            {/* <LeadProducts /> */}
            {/* <div>
                <LeadHowTo2 />
            </div> */}
            {/* <div className="bg">
                <LeadTestimonials />
            </div> */}
            <Exklusives />
            <div ref={rabatt}>
                <h2 className="title bg has-text-centered hideme">
                    Hier sichern Sie sich Ihren Exklusiv-Rabatt!
                </h2>
            </div>
        </div>
    );
};

export default LeadPartnerExklusives;
