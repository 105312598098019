import React from 'react';
import Fade from 'react-reveal/Fade';
import YouTube from 'react-youtube';
import { Videoueberwachung } from './PriceCalculator/elements/Constants';

export default class LeadTestimonials extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city ? this.props.city.slug : '',
            show: false,
            player: null,
            ytVideoOpts: {
                height: (typeof window != 'undefined' && window.innerWidth  > 640) ? 360 : 163.1,
                width: (typeof window != 'undefined' && window.innerWidth > 640) ? 640 : 290,
                playerVars: {
                    start: this.props.product === Videoueberwachung ? 530 : 266
                }
            }
        };
        this.handleClick = this.handleClick.bind(this);
        this._onReady = this._onReady.bind(this);
    }

    handleClick() {
        if (this.state.show) {
            this.state.player.pauseVideo();
        }
        this.setState({
            show: !this.state.show
        });
        document.getElementById('video-isabeau').classList.toggle('is-hidden');
    }

    _onReady(event) {
        this.setState({
            player: event.target
        });
    }

    render() {
        return (
            <div className="testimonials">

                <h2 className="title">99% Zufriedenheit - <br className="is-hidden-tablet"/>Das sagen unsere Kunden <br className="is-hidden-tablet"/>über uns</h2>

                <div className="columns is-centered">

                    <div className="column is-three-fifths-widescreen is-three-quarter-desktop testimonials">

                        <Fade right>
                            <div className="testimonial columns is-vcentered">
                                <div className="column is-narrow-tablet">
                                    <figure className="image container house">
                                        <img src="\img\elements\testimonials\alarmanlage-einfamilienhaus.jpg"/>
                                    </figure>
                                </div>
                                <div className="column is-three-fifths-widescreen text">
                                    <p>
                                        "Hier habe ich die beste Funk-Alarmanlage (Marke Daitem) zum besten Preis bekommen. Der Service war insgesamt von der Beratung bis zur Installation richtig gut. Da nahmen sich die Mitarbeiter viel Zeit für unsere Fragen."
                                    </p>
                                    <div className="rating float-left">
                                        <figure className="image">
                                            <img className="star" src="\img\elements\testimonials\5-sterne-bewertung-meinalarm24.png"/>
                                        </figure>
                                        <p className="name">Familie W. aus Essen</p>
                                    </div>
                                </div>
                            </div>
                        </Fade>

                        <Fade left>
                            <div className="testimonial columns has-text-right is-vcentered is-hidden-mobile">
                                <div className="column is-hidden-touch is-hidden-desktop-only"/>
                                <div className="column is-three-fifths-widescreen">
                                    <p>
                                        "MeinAlarm24 macht einen tadellosen Job, wenn es darum geht für mehr Sicherheit in unserem Zuhause zu sorgen. Meine Schwester hat sich auch gleich ein System über das Unternehmen installieren lassen."
                                    </p>
                                    <div className="rating float-right">
                                        <figure className="image float-right">
                                            <img className="star" src="\img\elements\testimonials\5-sterne-bewertung-meinalarm24.png"/>
                                        </figure>
                                        <p className="name">Herr und Frau B. aus Hamburg</p>
                                    </div>
                                </div>
                                <div className="column is-narrow">
                                    <figure className="image house">
                                        <img src="\img\elements\testimonials\alarmanlage-haus.jpg"/>
                                    </figure>
                                </div>
                            </div>
                        </Fade>

                        <Fade right>
                            <div className="testimonial columns is-vcentered">
                                <div className="column is-narrow-tablet">
                                    <figure className="image container house">
                                        <img src="\img\elements\testimonials\alarmanlage-gewerbe.jpg"/>
                                    </figure>
                                </div>
                                <div className="column is-three-fifths-widescreen">
                                    <p>
                                        "Wir haben uns über MeinAlarm24 das Büro absichern lassen. Alarmanlage & Videokameras. Der gesamte Prozess lief wahnsinnig problemlos ab - das hätten wir so nicht erwartet."
                                    </p>
                                    <div className="rating float-left">
                                        <figure className="image">
                                            <img className="star" src="\img\elements\testimonials\5-sterne-bewertung-meinalarm24.png"/>
                                        </figure>
                                        <p className="name">Herr G. aus Berlin</p>
                                    </div>
                                </div>
                            </div>
                        </Fade>

                        <div className="video-section">
                            {!this.state.show &&
                                <button type="button" className="preisrechner-btn preisrechner-btn-primary video-btn" onClick={this.handleClick}>
                                    <i className="fab fa-youtube"/>Zum Kundenvideo
                                </button>
                            }
                            {/* {this.state.show &&
                                <PriceCalculationButton city={this.state.city} product={this.props.product}/>
                            } */}
                            <Fade top when={this.state.show}>
                                <div id="video-isabeau" className="is-hidden">
                                    <div className="container">
                                        {this.state.show &&
                                            <YouTube
                                                videoId={this.props.product === Videoueberwachung ? '-uTzAnFGOHI' : 'ruuQxS56T4U'}
                                                opts={this.state.ytVideoOpts}
                                                onReady={this._onReady}
                                            />
                                        }
                                    </div>
                                </div>
                            </Fade>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
