import React from "react";
import { Link } from "gatsby";
const TickBlue = "/img/homepage/icons8-done-30.png";
function ListItem(props) {
    return (
        <p style={{ marginTop: "12px" }}>
            <img
                src={TickBlue}
                style={{
                    width: "35px",
                    height: "35px",
                    verticalAlign: "middle",
                    display: "block",
                    float: "left",
                }}
            />
            <span
                style={{
                    position: "relative",
                    // top: "-7px",
                    verticalAlign: "middle",
                    left: "5px",

                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    // webkitLineClamp: "3",
                    webkitBoxOrient: "vertical",
                }}
                className="font-pb3"
            >
                <b> {props.text}</b>
            </span>
        </p>
    );
}
export default class NewHomeWeSecure extends React.Component {
    render() {
        return (
            <div style={{ backgroundColor: "#F2F2F2" }}>
                <div
                    className="section how-to2"
                    style={{ marginTop: "40px", paddingBottom: "60px" }}
                >
                    <div className="body">
                        <div className="columns">
                            <div className="column has-text-centered">
                                <h2 className="title">
                                    Was macht MeinAlarm24?
                                </h2>
                                {/* <h3>
                                    Wir vertreiben nur ausgewählte Produkte von
                                    renommierten Herstellern.
                                </h3> */}
                            </div>
                        </div>

                        <div>
                            <div className="columns">
                                <div className="column has-text-centered">
                                    <div
                                        className="card"
                                        style={{
                                            border: "4px solid darkgrey",
                                            backgroundColor: "white",
                                        }}
                                    >
                                        <h2 className="h2kopca1">
                                            <b>Alarmanlagen</b>
                                        </h2>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "25px",
                                                textAlign: "initial",
                                                marginLeft: "15px",
                                            }}
                                        >
                                            <ListItem text="Modernste Technik von Testsiegern" />
                                            <ListItem text="Aufschaltung auf eine 24/7 Notrufleitstelle" />
                                            <ListItem text="KfW-Zuschuss bis zu 1.600 €" />
                                        </div>
                                        <div
                                            className="card-title"
                                            style={{
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                            }}
                                        >
                                            <img
                                                style={{ width: "10rem" }}
                                                src="/img/jablotron-ja-100-bedienelement.png"
                                                alt="Alarmgen"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="column has-text-centered">
                                    <div
                                        className="card"
                                        style={{
                                            border: "4px solid darkgrey",
                                            backgroundColor: "white",
                                        }}
                                    >
                                        <h2 className="h2kopca1">
                                            <b>Videoüberwachung</b>
                                        </h2>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "25px",
                                                textAlign: "initial",
                                                marginLeft: "15px",
                                            }}
                                        >
                                            <ListItem text="4K Ultra HD " />
                                            <ListItem text="Echtzeitüberwachung über Smartphone App" />
                                            <ListItem text="Gezielte Aufnahme von Ereignissen" />
                                        </div>
                                        <div
                                            className="card-title"
                                            style={{
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                            }}
                                        >
                                            <img
                                                style={{ width: "10rem" }}
                                                src="/img/bullet-kamera-dahua-2.png"
                                                alt="Alarmgen"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="column has-text-centered">
                                    <div
                                        className="card"
                                        style={{
                                            border: "4px solid darkgrey",
                                            backgroundColor: "white",
                                        }}
                                    >
                                        <h2>
                                            <b>Brandschutz</b>
                                        </h2>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "25px",
                                                textAlign: "initial",
                                                marginLeft: "15px",
                                            }}
                                        >
                                            <ListItem text="Vernetzte Brandwarnanalgen" />
                                            <ListItem text="Aufschaltung auf eine 24/7 Notrufleitestelle" />
                                            <ListItem text="Rundum-Sorglos-Paket" />
                                        </div>
                                        <div
                                            className="card-title"
                                            style={{
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                            }}
                                        >
                                            <img
                                                style={{ width: "10rem" }}
                                                src="/img/MeinAlarm24_Hekatron_Brandmeldeanlage.png"
                                                alt="Alarmgen"
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="column has-text-centered">
                                    <div
                                        className="card"
                                        style={{
                                            border: "4px solid darkgrey",
                                            backgroundColor: "white",
                                        }}
                                    >
                                        <h2 className="h2kopca1">
                                            <b>Elektronische Türschlösser</b>
                                        </h2>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "25px",
                                                textAlign: "initial",
                                                marginLeft: "15px",
                                            }}
                                        >
                                            <ListItem text="Smarte Zutrittskontrolle" />
                                            <ListItem text="Steuerung auch über Smartphone App" />
                                            <ListItem text="Keine hohen Kosten bei Schlüsselverlust" />
                                        </div>
                                        <div
                                            className="card-title"
                                            style={{
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                            }}
                                        >
                                            <img
                                                style={{ width: "10rem" }}
                                                src="/img/Türschloss.png"
                                                alt="Alarmgen"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
